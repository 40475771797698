<template>
  <v-expansion-panels
    focusable 
    v-if="list"
    class="turismo"
    style="margin-top:20px"
  >
      <v-layout wrap
        v-bind:class="'itemDraggable'"
      >
        <v-flex xs12 style="padding: 10px;background-color:white;margin-bottom:1px;cursor:pointer;color: #00000080">
          {{ $t('turismo.survey.questions', locale) }}
        </v-flex>
      </v-layout>
    <draggable
      v-model="list"
      :draggable="'.itemDraggable'"
      style="width:100%;"
      v-bind="dragOptions"
      :animation="200"
      group="description"
      ghostClass="ghost"
      @start="handleSortStart()"
      @end="handleSortItems(list)"
    >
      <v-layout wrap
        v-for="(item) in list"
        :key="item.ID"
        v-bind:class="'itemDraggable'"
        @click="onEdit(item.ID)"
      >
        <v-flex xs12 style="padding: 10px;background-color:white;margin-bottom:1px;cursor:pointer;">
          {{ item.ident.Name }}
        </v-flex>
      </v-layout>
    </draggable>
  </v-expansion-panels>
</template>
<script>
import draggable from 'vuedraggable'
import api from '@/services/api'
export default {
  name: 'list',
  components: {
    draggable,
  },
  props: {
    items: {
      type: Array,
      default: null
    },
    onEdit: {
      type: Function,
      required: true,
    },
  },
  data: () => ({
    list: null,
  }),
  watch: {
    items (v) {
      this.list = v
    },
  },
  computed: {
    dragOptionsDisabled() {
      return {
        animation: 200,
        group: "description",
        disabled: true,
        ghostClass: "ghost"
      }
    },
    dragOptions() {
      return {
        animation: 200,
        group: "description",
        disabled: false,
        ghostClass: "ghost"
      }
    },
  },
  mounted () {
    this.list = this.items
  },
  methods: {
    handleSortStart () {

    },
    handleSortItems (v) {
      if (!v || v.length === 0) return
      for (let i = 0; i < v.length; i++)
        v[i].ORD = i

      api.addItem('turismo', `v1/private/surveys/sort/`, v)
    },
  },
}
</script>
<style>
.add-menu {
  height: 100%;
  vertical-align: middle;
  cursor: pointer;
}
.turismo .v-expansion-panel-content__wrap {
  padding: 0 !important;
}
.workspace-name {
  font-size: 0.7rem !important;
  opacity: 0.5;
}
</style>

